// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-ellipsis {
  display: inline-block;
  position: absolute;
  left: 50vw;
  top: 50vh;
  width: 80px;
  height: 80px;
  z-index: 10;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #2a3042;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Spinner/Spinner.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AACF;AACE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,mDAAA;AACJ;AACI;EACE,SAAA;EACA,sCAAA;AACN;AAEI;EACE,SAAA;EACA,sCAAA;AAAN;AAGI;EACE,UAAA;EACA,sCAAA;AADN;AAII;EACE,UAAA;EACA,sCAAA;AAFN;;AAOA;EACE;IACE,mBAAA;EAJF;EAMA;IACE,mBAAA;EAJF;AACF;AAOA;EACE;IACE,mBAAA;EALF;EAOA;IACE,mBAAA;EALF;AACF;AAQA;EACE;IACE,0BAAA;EANF;EAQA;IACE,6BAAA;EANF;AACF","sourcesContent":[".lds-ellipsis {\n  display: inline-block;\n  position: absolute;\n  left: 50vw;\n  top: 50vh;\n  width: 80px;\n  height: 80px;\n  z-index: 10;\n\n  div {\n    position: absolute;\n    top: 33px;\n    width: 13px;\n    height: 13px;\n    border-radius: 50%;\n    background: #2a3042;\n    animation-timing-function: cubic-bezier(0, 1, 1, 0);\n\n    &:nth-child(1) {\n      left: 8px;\n      animation: lds-ellipsis1 0.6s infinite;\n    }\n\n    &:nth-child(2) {\n      left: 8px;\n      animation: lds-ellipsis2 0.6s infinite;\n    }\n\n    &:nth-child(3) {\n      left: 32px;\n      animation: lds-ellipsis2 0.6s infinite;\n    }\n\n    &:nth-child(4) {\n      left: 56px;\n      animation: lds-ellipsis3 0.6s infinite;\n    }\n  }\n}\n\n@keyframes lds-ellipsis1 {\n  0% {\n    transform: scale(0);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n\n@keyframes lds-ellipsis3 {\n  0% {\n    transform: scale(1);\n  }\n  100% {\n    transform: scale(0);\n  }\n}\n\n@keyframes lds-ellipsis2 {\n  0% {\n    transform: translate(0, 0);\n  }\n  100% {\n    transform: translate(24px, 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
