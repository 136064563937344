// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableGrid_button__WCihT {
  position: fixed !important;
  bottom: 4px;
  left: 40%;
}`, "",{"version":3,"sources":["webpack://./src/pages/SearchPlayerUserSessions/TableGrid.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,WAAA;EACA,SAAA;AACF","sourcesContent":[".button {\n  position: fixed !important;\n  bottom: 4px;\n  left: 40%;\n  //&:last-child {\n  //  left: 78%;\n  //}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `TableGrid_button__WCihT`
};
export default ___CSS_LOADER_EXPORT___;
