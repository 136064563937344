export const names = {
  Home: 'Home',
  Dashboard: 'Dashboard',
  Partners: 'Partners',
  Games: 'Games',
  СurrencyGames: 'Currency/Games',
  SearchPlayer: 'SearchPlayer',
  Players: 'Players',
  SessionEvents: 'Session Events',
  Login: 'Login',
  SessionsForGameCurrency: 'Sessions For Game Currency',
};
